<template>
  <div>
    <form-wizard
      color="#38B22D"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Update"
      back-button-text="Previous"
      class="mb-3 mt-0"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        title="Candidate Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
        class="mt-0 pt-0"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="candidate-form" class="px-2 pt-0">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Enter Candidate Account Details.</h5>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <label class="required-l" for="name">Name</label>
                    <b-form-input
                      id="name"
                      v-model="data_local.name"
                      name="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Candidate Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <label class="required-l" for="email">Email</label>
                    <b-form-input
                      id="email"
                      v-model="data_local.email"
                      type="email"
                      name="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="candidate@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <label class="mr-sm-2 required-l" for="designation"
                  >Position
                  <b-button
                    v-b-modal.designation-modal
                    size="sm"
                    variant="outline-light"
                    class="border-0 p-0"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      class="text-warning"
                  /></b-button>
                </label>
                <v-select
                  v-model="data_local.designation_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="designationOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="designation"
                  value.sync="designation_id"
                  placeholder="Select Position.."
                />
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    rules="required|numeric"
                  >
                    <label class="required-l" for="mobile">Mobile</label>
                    <b-form-input
                      id="mobile"
                      v-model="data_local.mobile_number"
                      name="mobile_number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Mobile Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label class="mr-sm-2" for="source"
                  >Source
                  <b-button
                    v-b-modal.source-modal
                    size="sm"
                    variant="outline-light"
                    class="border-0 p-0"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      class="text-warning"
                  /></b-button>
                </label>
                <v-select
                  v-model="data_local.source"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="candidateSourceOption"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  input-id="source"
                  value.sync="source"
                  placeholder="Select Source.."
                />
              </b-col>

              <b-col md="6" v-if="data_local.source == 'other'">
                <b-form-group>
                  <label class="required-l" for="other-source"
                    >Other Source name</label
                  >
                  <b-form-input
                    id="other-source"
                    v-model="data_local.other_source"
                    name="other_source"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="data_local.source == 'Hire a star'">
                <b-form-group>
                  <label class="required-l" for="employee">Employee name</label>
                  <v-select
                    v-model="data_local.referred_by"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employeesList"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    input-id="employee"
                    placeholder="Select Employees.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date of Birth" label-for="date-of-birth">
                  <flat-pickr
                    v-model="data_local.date_of_birth"
                    :config="{
                      altFormat: 'd F Y',
                      altInput: 'true',
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                    }"
                    class="form-control"
                    name="Date of Birth"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="CTC" label-for="ctc">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="ctc"
                      v-model="data_local.ctc"
                      :type="data_local.is_ctc_private ? 'password' : 'text'"
                      class="form-control-merge"
                      name="ctc"
                      placeholder="CTC"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        v-b-tooltip.hover.top="'Hide CTC to everyone'"
                        class="cursor-pointer"
                        :icon="
                          data_local.is_ctc_private ? 'EyeOffIcon' : 'EyeIcon'
                        "
                        @click="togglePrivacy(0)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="ECTC" label-for="ectc">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="ectc"
                      v-model="data_local.expected_ctc"
                      :type="data_local.is_ectc_private ? 'password' : 'text'"
                      name="expected_ctc"
                      class="form-control-merge"
                      placeholder="ECTC"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        v-b-tooltip.hover.top="'Hide ECTC to everyone'"
                        class="cursor-pointer"
                        :icon="
                          data_local.is_ectc_private ? 'EyeOffIcon' : 'EyeIcon'
                        "
                        @click="togglePrivacy(1)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Notice Period (Days)"
                  label-for="notice period"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="probation period"
                    rules="numeric"
                  >
                    <b-form-input
                      id="notice-period"
                      v-model="data_local.notice_period"
                      name="notice_period"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Notice Period in Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Total Experience"
                  label-for="total-experience"
                >
                  <b-row>
                    <b-col cols="6">
                      <v-select
                        v-model="experienceInYear"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="yearsOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        input-id="experience-in-year"
                        placeholder="In Years"
                      />
                    </b-col>
                    <b-col cols="6">
                      <v-select
                        v-model="experienceInMonths"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthsOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        input-id="experience-in-months"
                        placeholder="In Months"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Relevant Experience"
                  label-for="relevant-experience"
                >
                  <b-row>
                    <b-col cols="6">
                      <v-select
                        v-model="relevantExperienceInYear"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="yearsOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        input-id="relevant-experience-in-year"
                        placeholder="In Years"
                      />
                    </b-col>
                    <b-col cols="6">
                      <v-select
                        v-model="relevantExperienceInMonths"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthsOptions"
                        :reduce="(label) => label.code"
                        label="label"
                        :clearable="false"
                        input-id="relevant-experience-in-months"
                        placeholder="In Months"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Current Company Name"
                  label-for="current-company-name"
                >
                  <b-form-input
                    id="current-company-name"
                    v-model="data_local.current_company"
                    name="current_company"
                    placeholder="Current Company Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Skills" label-for="skills">
                  <b-form-textarea
                    id="skills"
                    v-model="data_local.skills"
                    name="skills"
                    placeholder="Skills"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Candidate Status"
                  label-for="candidate-status"
                >
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="candidateStatusOption"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-status"
                    placeholder="Select Status.."
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="data_local.status == 2">
                <b-form-group
                  label="Referral Reject Reason"
                  label-for="referral_reject_reason"
                >
                  <b-form-textarea
                    id="referral_reject_reason"
                    v-model="data_local.referral_reject_reason"
                    name="referral_reject_reason"
                    placeholder="Referral Reject Reason"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Remark" label-for="remark">
                  <b-form-textarea
                    id="remark"
                    v-model="data_local.remark"
                    name="remark"
                    placeholder="Remark"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Gender" label-for="candidate-gender">
                  <v-select
                    v-model="data_local.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="candidateGenderOption"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    input-id="candidate-gender"
                    placeholder="Select Gender.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Marital Status"
                  label-for="candidate-marital-status"
                >
                  <v-select
                    v-model="data_local.marital_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="candidateMaritalStatusOption"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    input-id="candidate-marital-status"
                    placeholder="Select Marital Status.."
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Current Location"
                  label-for="current-location"
                >
                  <b-form-textarea
                    id="current-location"
                    v-model="data_local.current_address"
                    name="current_address"
                    placeholder="Current Location"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md="6" /> -->
              <b-col md="6">
                <b-form-group
                  v-if="data_local.resume_file_path === ''"
                  label="Upload Resume"
                  label-for="files"
                >
                  <file-pond
                    ref="pond"
                    name="files[]"
                    max-file-size="5MB"
                    max-files="1"
                    accepted-file-types="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX file"
                    allow-multiple="false"
                    image-preview-height="120px"
                  />
                  <small class="text-warning flex items-center">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be less than 5MB and allowed formats are
                    <b>.doc, .docx, .pdf</b>
                  </small>
                </b-form-group>

                <div v-else>
                  Uploaded Resume
                  <feather-icon
                    v-b-tooltip.hover.top="'View Resume'"
                    class="btn-link cursor-pointer ml-1"
                    icon="EyeIcon"
                    @click.stop.prevent="
                      openWindow(data_local.resume_file_path)
                    "
                  />
                  <feather-icon
                    v-b-tooltip.hover.top="'Remove Resume'"
                    class="btn-link cursor-pointer ml-1"
                    icon="TrashIcon"
                    @click.stop.prevent="removeResume()"
                  />
                </div>
              </b-col>

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group v-if="item.type == 'number'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'text'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'url'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'rating'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group v-if="item.type == 'password'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'textarea'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group v-if="item.type == 'date'">
                    <label
                      v-bind:class="item.required == 'no' ? '' : 'required-l'"
                      :for="item.name"
                      >{{ item.label }}</label
                    >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <b-modal
      id="source-modal"
      ref="modal"
      size="md"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addSource"
    >
      <template #modal-header="{ close }">
        <h5>Add Source</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col cols="2"> # </b-col>
            <b-col cols="4"> Source </b-col>
            <b-col cols="6" class="text-center"> Action </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-for="(source, index) in candidateSourceOption"
          :key="source.id"
        >
          <b-row>
            <b-col cols="2">
              {{ ++index }}
            </b-col>
            <b-col cols="4">
              {{ source.label }}
            </b-col>
            <b-col cols="6" class="text-center">
              <b-button
                variant="danger"
                size="sm"
                @click="removeSource(source)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="text-nowrap">Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <form ref="form" @submit.stop.prevent="addSource" class="mt-1">
        <b-form-group
          label="Source Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="sourceNameState"
        >
          <b-form-input
            id="name-input"
            v-model="sourceName"
            :state="sourceNameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="designation-modal"
      ref="modal"
      size="md"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDesignation"
    >
      <template #modal-header="{ close }">
        <h5>Add Designation</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <!-- <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col cols="2"> # </b-col>
            <b-col cols="4"> Designation </b-col>
            <b-col cols="6" class="text-center"> Action </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-for="(item, index) in designationOptions"
          :key="item.id"
        >
          <b-row>
            <b-col cols="2">
              {{ ++index }}
            </b-col>
            <b-col cols="4">
              {{ item.label }}
            </b-col>
            <b-col cols="6" class="text-center">
              <b-button
                variant="danger"
                size="sm"
                @click="removeSource(item)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="text-nowrap">Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group> -->
      <form ref="form" @submit.stop.prevent="addDesignation" class="mt-1">
        <b-form-group
          label="Designation Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="designationNameState"
        >
          <b-form-input
            id="name-input"
            v-model="designationName"
            :state="designationNameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import candidateStoreModule from "../candidateStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    FilePond,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      email,
      numeric,
      determineOption: [
        {
          code: "Worked together in the past",
          label: "Worked together in the past",
        },
        {
          code: "Socially connected",
          label: "Socially connected",
        },
        {
          code: "Relative",
          label: "Relative",
        },
        {
          code: "Ex Employee",
          label: "Ex Employee",
        },
        {
          code: "Others",
          label: "Others",
        },
      ],
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      candidateStatusOption: [
        { label: "In Progress", code: 0 },
        { label: "Selected", code: 1 },
        { label: "Rejected", code: 2 },
        { label: "No Show", code: 3 },
        { label: "Joined", code: 4 },
        { label: "Not Suitable", code: 5 },
      ],
      candidateGenderOption: [
        { label: "Male", code: "male" },
        { label: "Female", code: "female" },
        { label: "Other", code: "other" },
      ],
      candidateMaritalStatusOption: [
        { label: "Single", code: "single" },
        { label: "Married", code: "married" },
        { label: "Widowed", code: "widowed" },
        { label: "Divorced", code: "divorced" },
        { label: "Separated", code: "separated" },
      ],
      candidateSourceOption: [
        { label: "Naukri", code: "naukri" },
        { label: "Indeed", code: "indeed" },
        { label: "Hire a star", code: "hire_a_star" },
        { label: "Linkedin", code: "linkedin" },
        { label: "Other", code: "other" },
      ],
      employeesList: [],
      yearsOptions: Array.from({ length: 31 }, (v, i) => i).map(function (
        a,
        b
      ) {
        return { label: b + " Years", code: b };
      }),
      monthsOptions: Array.from({ length: 12 }, (v, i) => i).map(function (
        a,
        b
      ) {
        return { label: b + " Months", code: b };
      }),

      experienceInYear: 0,
      experienceInMonths: 0,
      relevantExperienceInYear: 0,
      relevantExperienceInMonths: 0,

      data_local: {
        name: "",
        email: "",
        date_of_birth: "",
        mobile_number: "",
        skills: "",
        ctc: "",
        expected_ctc: "",
        notice_period: "",
        years_of_experience: "",
        years_of_relevant_experience: "",
        current_company: "",
        previous_company: "",
        resume_file_path: "",
        status: 0,
        current_address: "",
        marital_status: "",
        gender: "",
        source: "",
        other_source: "",
        referred_by: "",
        is_ctc_private: false,
        is_ectc_private: false,
        get_customfields_data: [],
        designation_id: "",
      },
      sourceName: "",
      sourceNameState: null,
      customFields: [],
      designationOptions: [],
      designationName: "",
      designationNameState: null,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-candidates";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, candidateStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
    this.fetchEmployees();
    this.fetchCandidateSource();
    this.fetchDesignationsList();
  },
  methods: {
    resetModal() {
      this.sourceName = "";
      this.designationName = "";
    },
    fetchCandidateSource() {
      const self = this;
      this.$store
        .dispatch("app-candidates/fetchCandidateSource")
        .then((res) => {
          if (res.data.success) {
            this.candidateSourceOption = res.data.data;
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Failure to obtain candidate's source list.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure to obtain role list.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            })
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addDesignation() {
      const self = this;
      let params = [];
      params.name = self.designationName;
      this.$store
        .dispatch("app-candidates/addDesignation", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Designation Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Designation Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchDesignationsList();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Designation Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Designation Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    removeDesignation(designation) {
      const self = this;
      let params = [];
      params.id = designation.id;
      this.$store
        .dispatch("app-candidates/removeDesignation", params)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Designation Removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Designation Removed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          this.fetchCandidateDesignation();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Designation Removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Designation Removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchDesignationsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-candidates/fetchDesignationsList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    addSource() {
      const self = this;
      let params = [];
      params.name = self.sourceName;
      this.$store
        .dispatch("app-candidates/addSource", params)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Source Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Source Added',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchCandidateSource();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Source Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Source Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    removeSource(source) {
      const self = this;
      let params = [];
      params.id = source.id;
      this.$store
        .dispatch("app-candidates/removeSource", params)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Source Removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Source Removed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.fetchCandidateSource();
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Source Removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Source Removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    togglePrivacy(type) {
      if (type == 0) {
        this.data_local.is_ctc_private = !this.data_local.is_ctc_private;
      } else {
        this.data_local.is_ectc_private = !this.data_local.is_ectc_private;
      }
    },
    openWindow(link) {
      window.open(link);
    },
    removeResume() {
      this.data_local.resume_file_path = "";
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-candidates/fetchCandidate", userId)
        .then((res) => {
          this.data_local = res.data.data;

          this.experienceInYear = this.getYearsCount(
            this.data_local.years_of_experience
          );
          this.experienceInMonths = this.getMonthsCount(
            this.data_local.years_of_experience
          );

          this.relevantExperienceInYear = this.getYearsCount(
            this.data_local.years_of_relevant_experience
          );
          this.relevantExperienceInMonths = this.getMonthsCount(
            this.data_local.years_of_relevant_experience
          );
        })
        .catch((err) => {
          if (err.response.status === 404) {
            return;
          }
          console.error(err);
        });
    },
    fetchEmployees() {
      this.$store
        .dispatch("app/fetchInterviewers")
        .then((res) => {
          this.employeesList = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getYearsCount(year) {
      return parseInt(year / 12);
    },
    getMonthsCount(year) {
      return parseInt(year % 12);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("candidate-form"));
      formData.delete("files[]"); // data is the name of the original input file; import to delete
      if (self.data_local.resume_file_path === "") {
        const files = self.$refs.pond.getFiles();
        const keys = Object.keys(self.$refs.pond.getFiles());
        for (let i = 0; i < keys.length; i += 1) {
          formData.append(`files[${i}]`, files[i].file);
        }
      }

      formData.append("is_ctc_private", this.data_local.is_ctc_private ? 1 : 0);
      formData.append(
        "is_ectc_private",
        this.data_local.is_ectc_private ? 1 : 0
      );

      formData.append("date_of_birth", self.data_local.date_of_birth);
      formData.append("status", self.data_local.status);
      formData.append("_method", "PATCH");

      formData.append("marital_status", self.data_local.marital_status);
      formData.append("gender", self.data_local.gender);
      formData.append("source", self.data_local.source);
      formData.append("referred_by", self.data_local.referred_by);
      formData.append("designation_id", self.data_local.designation_id);

      self.data_local.years_of_experience =
        self.experienceInYear * 12 + self.experienceInMonths;
      self.data_local.years_of_relevant_experience =
        self.relevantExperienceInYear * 12 + self.relevantExperienceInMonths;

      formData.append(
        "years_of_experience",
        self.data_local.years_of_experience
      );
      formData.append(
        "years_of_relevant_experience",
        self.data_local.years_of_relevant_experience
      );

      this.$store
        .dispatch("app-candidates/updateCandidate", {
          id: self.$route.params.id,
          data: formData,
        })
        .then((res) => {
          if (res.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Candidate Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Candidate Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$router.push("/candidates");
          } else {

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Candidate update failure',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Candidate update failure.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Candidate update failure.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Candidate update failure',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;
      if (
        self.data_local.resume_file_path === "" &&
        this.$refs.pond.getFiles().length === 0
      ) {
        // self.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Resume Needed",
        //     icon: "BellIcon",
        //     variant: "danger",
        //     text: "Kindly upload a resume",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Resume Needed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Kindly upload a resume',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        return false;
      }
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-candidates/getCustomFields")
        .then((res) => {
          if (res.data.success) {
            self.customFields = res.data.data;
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Custom Fields Getting failure.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting failure.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
